import {
  Box,
  Grid, TableContainer, TablePagination,

  Tooltip, Typography
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import MuiAlert from "@material-ui/lab/Alert";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useConfirm } from "material-ui-confirm";
import Moment from "moment";
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import Spinner from "../../shared/components/Spinner";
import useApi from "../../shared/hooks/api";
import api from "../../shared/utils/api";
import {
  DialogContentNoScroll, SearchPaper, StyledPaper,
  StyledToolBar
} from "./Styles";



const JobListPage = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [tab, setTab] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [score, setScore] = React.useState({});
  const [certificateInfo, setCertificateInfo] = React.useState({});
  const [user, setUser] = React.useState({});
  const [jobError, setJobError] = React.useState({});
  const [jobId, setJobId] = React.useState(null);
  const [hasSearched, setHasSearched] = React.useState(false);
  const [apiMessage, setApiMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [certUrl, setCertUrl] = React.useState("#");
  const [apiResponseType, setApiResponseType] = React.useState("error");
  const history = useHistory();
  const confirmDelete = useConfirm();
  const [{ data, error, isLoading }, fetchJobs] = useApi.get("/api/jobs", {
    skip: 0,
    take: 20,
    tab: 0,
    search: "",
  });

  if (error) {
    return (
      <Fragment>
        <Typography variant="h1">Error Loading Page</Typography>
      </Fragment>
    );
  }
  let jobList = data ? data.list : [];

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const getJobsWithCurrentConstraints = () => {
    fetchJobs({
      skip: page * rowsPerPage,
      take: rowsPerPage,
      tab: tab,
      search: searchText,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchJobs({
      skip: newPage * rowsPerPage,
      take: rowsPerPage,
      tab: tab,
      search: searchText,
    });
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    fetchJobs({
      skip: 0,
      take: rowsPerPage,
      tab: newValue,
      search: searchText,
    });
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };

  const handleSearchClick = (event) => {
    event.preventDefault();
    setPage(0);
    fetchJobs({
      skip: 0,
      take: rowsPerPage,
      tab: tab,
      search: searchText,
    });

    if (searchText !== "") {
      setHasSearched(true);
    } else {
      setHasSearched(false);
    }

    console.log("COUNT", data.count);
  };

  const handleClearSearchClick = () => {
    setPage(0);
    setSearchText("");

    if (hasSearched) {
      fetchJobs({
        skip: 0,
        take: rowsPerPage,
        tab: tab,
        search: "",
      });
      setHasSearched(false);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    fetchJobs({
      skip: 0,
      take: parseInt(event.target.value, 10),
      tab: tab,
    });
  };

  const logout = async () => {
    history.push("/authenticate");
    await api.post("/api/logout");
  };

  const deleteRecord = async (job) => {
    try {
      console.log(job)
      try {
        await confirmDelete({
          description: `This will permanently delete this entry.`,
        });
      } catch (error) {
        return;
      }
      setLoading(true);

      let r = await api.delete("/api/job", {
        job_id: job.job_id,
      });

      console.log(r);
      setApiResponseType("success");
      setSnackBarOpen(true);
      getJobsWithCurrentConstraints();
      setApiMessage("Success");
    } catch (error) {
      setApiResponseType("error");
      setApiMessage(error.message);
      setSnackBarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  Moment.locale("en");

  let renderedList = jobList.map((job) => (
    <TableRow key={job.job_id}>
      <TableCell align="left">
        {job.user.first_name + " " + job.user.last_name}
      </TableCell>
      <TableCell align="left">{job.certificate_info.course_id}</TableCell>
      <TableCell scope="row">
        {Moment.utc(job.certificate_info.certificate_date).format(
          "MMMM Do YYYY, h:mm:ss a"
        )}
      </TableCell>
      <TableCell align="center">
        {job.return_code === "S001" ? (
          <CheckIcon color="primary" />
        ) : job.return_code === "N001" ? (
          <PriorityHighIcon color="secondary" />
        ) : (
          <ClearIcon color="error" />
        )}
      </TableCell>
      <TableCell align="right">
        {job.certificate_info.nccaom_certificate_code}
      </TableCell>
      <TableCell scope="row">
        {Moment(job.date).format(
          "MMMM Do YYYY, h:mm:ss a"
        )}
      </TableCell>
      <TableCell align="right">
        {job.return_code !== "S001" && (
          <Fragment>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  setCertificateInfo(job.certificate_info);
                  setUser(job.user);
                  setScore(job.score);
                  setJobError(job.return_message);
                  setJobId(job.job_id);
                  setDialogOpen(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Resend">
              <IconButton
                onClick={async () => {
                  try {
                    setLoading(true);
                    let r = await api.post("/api/certificate", {
                      job_id: job.job_id,
                    });
                    console.log(r);
                    let certificate_no = r.certificate_no;
                    let certificate_url = r.certificate_url;
                    let passcode = r.passcode;
                    getJobsWithCurrentConstraints();
                    setApiResponseType("success");
                    setCertUrl(certificate_url);
                    setSnackBarOpen(true);
                    getJobsWithCurrentConstraints();
                    setApiMessage(
                      "Certificate created with Certificate NO: " +
                        certificate_no +
                        ", at " +
                        certificate_url +
                        " passcode " +
                        passcode
                    );
                  } catch (error) {
                    setApiResponseType("error");
                    setApiMessage(error.message);
                    setSnackBarOpen(true);
                  } finally {
                    setLoading(false);
                  }
                }}
                aria-label="redo"
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Fragment>
        )}
        {job.return_code === "S001" && (
          <Fragment>
            <Tooltip title="View Details">
              <IconButton
                onClick={() => {
                  setCertificateInfo(job.certificate_info);
                  setUser(job.user);
                  setScore(job.score);
                  setJobError(job.return_message);
                  setJobId(job.job_id);
                  setDialogOpen(true);
                }}
              >
                <ViewModuleIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="View Certificate">
              <IconButton
                onClick={() =>
                  window.open(
                    job.certificate_info.nccaom_certificate_url,
                    "_blank"
                  )
                }
              >
                <CardMembershipIcon />
              </IconButton>
            </Tooltip>
          </Fragment>
        )}
        <Fragment>
          <Tooltip title="Delete">
            <IconButton onClick={() => deleteRecord(job)} aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Fragment>
      </TableCell>
    </TableRow>
  ));

  return (
    <Fragment>
      <Snackbar
        style={{ cursor: "pointer" }}
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
      >
        <Alert onClose={handleSnackBarClose} severity={apiResponseType}>
          <Typography
            onClick={() => {
              window.open(certUrl, "_blank");
            }}
          >
            {apiMessage}
          </Typography>
        </Alert>
      </Snackbar>
      <AppBar position="static">
        <StyledToolBar>
          <Button onClick={logout} color="inherit">
            Logout
          </Button>
        </StyledToolBar>
      </AppBar>
      {(isLoading || loading) && <Spinner></Spinner>}
      <StyledPaper>
        <Paper>
          <Box display="flex" align="right">
            <Button
              style={{ margin: "10px" }}
              color="primary"
              variant="contained"
              onClick={() => {
                setCertificateInfo({
                  certificate_date: Moment(),
                });
                setUser({});
                setScore({});
                setJobError(null);
                setJobId(null);
                setDialogOpen(true);
              }}
            >
              New Certificate
            </Button>
            <SearchPaper style={{ margin: "10px 10px 0 auto" }} align="end">
              <form>
                <Box
                  display="inline"
                  visibility={searchText !== "" ? "visible" : "hidden"}
                >
                  <IconButton
                    onClick={handleClearSearchClick}
                    style={{ marginBottom: 3 }}
                  >
                    <ClearIcon></ClearIcon>
                  </IconButton>
                </Box>
                <InputBase
                  placeholder="Search"
                  inputProps={{ "aria-label": "search" }}
                  onChange={handleSearchChange}
                  value={searchText}
                ></InputBase>
                <IconButton
                  onClick={handleSearchClick}
                  aria-label="search"
                  type="submit"
                >
                  <SearchIcon />
                </IconButton>
              </form>
            </SearchPaper>
          </Box>

          <Tabs
            indicatorColor="primary"
            textColor="primary"
            aria-label="disabled tabs example"
            value={tab}
            onChange={handleTabChange}
          >
            <Tab label="All" />
            <Tab label="Failed" />
            <Tab label="Missing NCCAOM ID" />
          </Tabs>
        </Paper>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">NCCAOM Course ID</TableCell>
                <TableCell>Certificate Date</TableCell>
                <TableCell align="center">Success</TableCell>
                <TableCell align="right">Certificate Passcode</TableCell>
                <TableCell>Issue Date</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderedList}</TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={data ? data.count : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        ></TablePagination>
      </StyledPaper>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle align="center" id="form-dialog-title">
          {jobId ? (jobError == "Success" ? "View" : "Edit") : "New"}
        </DialogTitle>
        <DialogContentNoScroll>
          <form>
            <Typography variant="subtitle1">User Information</Typography>
            <Grid container spacing={3}>
              <Grid xs={6} item>
                <TextField
                  required
                  disabled={jobError === "Success"}
                  error={user?.first_name === ""}
                  value={user?.first_name}
                  onChange={(event) => {
                    setUser({
                      ...user,
                      first_name: event.target.value,
                    });
                  }}
                  fullWidth
                  label="First Name"
                ></TextField>
              </Grid>
              <Grid xs={6} item>
                <TextField
                  disabled={jobError === "Success"}
                  fullWidth
                  required
                  error={user?.last_name === ""}
                  value={user?.last_name}
                  onChange={(event) => {
                    setUser({
                      ...user,
                      last_name: event.target.value,
                    });
                  }}
                  label="Last Name"
                />
              </Grid>
              <Grid xs={6} item>
                <TextField
                  disabled={jobError === "Success"}
                  fullWidth
                  required
                  error={user?.email === ""}
                  value={user?.email}
                  onChange={(event) => {
                    setUser({
                      ...user,
                      email: event.target.value,
                    });
                  }}
                  label="Email"
                />
              </Grid>
              <Grid xs={6} item>
                <TextField
                  disabled={jobError === "Success"}
                  fullWidth
                  value={user?.nccaom_id}
                  onChange={(event) => {
                    setUser({
                      ...user,
                      nccaom_id: event.target.value,
                    });
                  }}
                  label="NCCAOM ID"
                />
              </Grid>
              <Grid xs={6} item>
                <TextField
                  disabled={jobError === "Success"}
                  fullWidth
                  value={user?.acupuncture_license}
                  onChange={(event) => {
                    setUser({
                      ...user,
                      acupuncture_license: event.target.value,
                    });
                  }}
                  label="Acupuncture License"
                />
              </Grid>
              <Grid item />
              <Grid xs={5} item></Grid>
            </Grid>
            <br />
            <br />
            <br />
            <Typography variant="subtitle1">Certificate Information</Typography>
            <Grid container spacing={3}>
              <Grid xs={6} item>
                <TextField
                  disabled={jobError === "Success"}
                  value={certificateInfo?.course_id}
                  error={certificateInfo?.course_id === ""}
                  onChange={(event) => {
                    setCertificateInfo({
                      ...certificateInfo,
                      course_id: event.target.value,
                    });
                  }}
                  fullWidth
                  required
                  label="NCCAOM Course ID"
                />
              </Grid>
              <Grid xs={6} item>
                <KeyboardDatePicker
                  disabled={jobError === "Success"}
                  value={
                    certificateInfo?.certificate_date
                      ? Moment(certificateInfo.certificate_date).utcOffset(0)
                      : Moment()
                  }
                  onChange={(date) => {
                    console.log(date.format("MM/DD/YYYY"));
                    let newDate = Moment(date.format("MM/DD/YYYY"));
                    newDate = newDate.utcOffset(0);
                    newDate = newDate.set("hour", 0);
                    newDate = newDate.set("minute", 0);
                    newDate = newDate.set("second", 0);
                    newDate = newDate.set("millisecond", 0);
                    console.log(newDate);
                    setCertificateInfo({
                      ...certificateInfo,
                      certificate_date: newDate,
                    });

                    console.log(this);
                  }}
                  autoOk
                  fullWidth
                  disableToolbar
                  variant="inline"
                  format="MM/DD/yyyy"
                  error={!certificateInfo?.certificate_date}
                  id="date-picker-inline"
                  label="Certificate Date"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid
                xs={12}
                hidden={!certificateInfo?.nccaom_certificate_id}
                item
              >
                <TextField
                  fullWidth
                  value={certificateInfo?.nccaom_certificate_id}
                  disabled
                  label="NCCAOM Certificate ID"
                />
              </Grid>
              <Grid
                xs={12}
                hidden={!certificateInfo?.nccaom_certificate_url}
                item
              >
                <TextField
                  fullWidth
                  value={certificateInfo?.nccaom_certificate_url}
                  disabled
                  label="NCCAOM Certificate URL"
                />
              </Grid>
              <Grid
                xs={12}
                hidden={!certificateInfo?.nccaom_certificate_code}
                item
              >
                <TextField
                  fullWidth
                  value={certificateInfo?.nccaom_certificate_code}
                  disabled
                  label="NCCAOM Certificate Code"
                />
              </Grid>
              <Grid xs={12} item>
                <TextField
                  fullWidth
                  value={certificateInfo?.course_name}
                  disabled={jobError === "Success"}
                  label="Course Name"
                  onChange={(event) => {
                    setCertificateInfo({
                      ...certificateInfo,
                      course_name: event.target.value,
                    });
                  }}
                />
              </Grid>
            </Grid>
            <br />
            <br />
            <br />
            <Typography variant="subtitle1">Credits</Typography>
            <Grid container spacing={3}>
              <Grid item>
                <TextField
                  disabled={jobError === "Success"}
                  type="number"
                  onChange={(event) => {
                    setScore({
                      ...score,
                      aom_bio: event.target.value,
                    });
                  }}
                  value={score?.aom_bio}
                  label="AOM BIO"
                />
              </Grid>
              <Grid item>
                <TextField
                  disabled={jobError === "Success"}
                  type="number"
                  onChange={(event) => {
                    setScore({
                      ...score,
                      aom_ac: event.target.value,
                    });
                  }}
                  value={score?.aom_ac}
                  label="AOM AC"
                />
              </Grid>
              <Grid item>
                <TextField
                  disabled={jobError === "Success"}
                  type="number"
                  onChange={(event) => {
                    setScore({
                      ...score,
                      aom_abt: event.target.value,
                    });
                  }}
                  value={score?.aom_abt}
                  label="AOM ABT"
                />
              </Grid>
              <Grid item>
                <TextField
                  disabled={jobError === "Success"}
                  type="number"
                  onChange={(event) => {
                    setScore({
                      ...score,
                      aom_om: event.target.value,
                    });
                  }}
                  value={score?.aom_om}
                  label="AOM OM"
                />
              </Grid>
              <Grid item>
                <TextField
                  disabled={jobError === "Success"}
                  type="number"
                  onChange={(event) => {
                    setScore({
                      ...score,
                      safety: event.target.value,
                    });
                  }}
                  value={score?.safety}
                  label="Safety"
                />
              </Grid>
              <Grid item>
                <TextField
                  disabled={jobError === "Success"}
                  type="number"
                  onChange={(event) => {
                    setScore({
                      ...score,
                      ethics: event.target.value,
                    });
                  }}
                  value={score?.ethics}
                  label="Ethics"
                />
              </Grid>
              <Grid item>
                <TextField
                  disabled={jobError === "Success"}
                  type="number"
                  onChange={(event) => {
                    setScore({
                      ...score,
                      pe: event.target.value,
                    });
                  }}
                  value={score?.pe}
                  label="PE"
                />
              </Grid>
              <Grid item>
                <TextField
                  disabled={jobError === "Success"}
                  type="number"
                  onChange={(event) => {
                    setScore({
                      ...score,
                      aom_ch: event.target.value,
                    });
                  }}
                  value={score?.aom_ch}
                  label="AOM CH"
                />
              </Grid>
            </Grid>
            <br />
            <br />
            <br />

            <Box hidden={!jobError || jobError === "Success"}>
              <Typography variant="subtitle1">Error Details</Typography>
              <Grid justify="center" container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    value={jobError}
                    fullWidth
                    disabled
                    multiline
                  ></TextField>
                  <br />
                  <br />
                </Grid>
              </Grid>
            </Box>
            <br />
          </form>
        </DialogContentNoScroll>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpen(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          {jobError !== "Success" && (
            <Button
              onClick={async () => {
                let formError;
                if (!certificateInfo?.course_id) {
                  certificateInfo.course_id = "";
                  formError = "Course ID cannot be empty.";
                }
                if (!certificateInfo?.certificate_date) {
                  certificateInfo.certificate_date = null;
                  formError = "Certificate Date cannot be empty.";
                }
                if (!user?.email) {
                  user.email = "";
                  formError = "Email cannot be empty.";
                }
                if (!user?.last_name) {
                  user.last_name = "";
                  formError = "Last Name cannot be empty.";
                }
                if (!user?.first_name) {
                  user.first_name = "";
                  formError = "First Name cannot be empty.";
                }

                if (formError) {
                  setApiResponseType("error");
                  setCertUrl("#");
                  setSnackBarOpen(true);
                  setApiMessage(formError);
                  return;
                }

                if (!jobId) {
                  try {
                    setLoading(true);
                    certificateInfo.certificate_date = certificateInfo.certificate_date
                      ? certificateInfo.certificate_date
                      : Moment();
                    let r = await api.post("/api/job", {
                      user,
                      certificateInfo,
                      score,
                      jobId,
                    });
                    let certificate_no = r.certificate_no;
                    let certificate_url = r.certificate_url;
                    let passcode = r.passcode;
                    setApiResponseType("success");
                    setCertUrl(certificate_url);
                    setSnackBarOpen(true);
                    setApiMessage(
                      "Certificate created with Certificate NO: " +
                        certificate_no +
                        ", at " +
                        certificate_url +
                        " passcode " +
                        passcode
                    );
                    setLoading(false);
                  } catch (error) {
                    setApiResponseType("error");
                    setCertUrl("#");
                    setSnackBarOpen(true);
                    setApiMessage(error.message);
                    setLoading(false);
                    return;
                  }
                } else {
                  try {
                    setLoading(true);
                    await api.patch("/api/job", {
                      user,
                      certificateInfo,
                      score,
                      jobId,
                    });
                    setApiResponseType("success");
                    setCertUrl("#");
                    setSnackBarOpen(true);
                    setApiMessage("Saved");
                    setLoading(false);
                  } catch (error) {
                    setApiResponseType("error");
                    setCertUrl("#");
                    setSnackBarOpen(true);
                    setApiMessage(error.message);
                    setLoading(false);
                    return;
                  }
                }

                getJobsWithCurrentConstraints(jobList);
                setDialogOpen(false);
              }}
              color="primary"
            >
              {jobId ? "Save" : "Submit"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default JobListPage;
