import axios from 'axios';

import history from '../../browserHistory';
import { objectToQueryString } from './url';

const defaults = {
  baseURL: process.env.API_URL || 'https://nccaom.healthyseminars.com/service',
  headers: () => ({
    'Content-Type': 'application/json'
  }),
  error: {
    code: 'INTERNAL_ERROR',
    message: 'Something went wrong. Please check your internet connection or contact our support.',
    status: 503,
    data: {},
  },
};

const api = (method, url, variables) =>
  new Promise((resolve, reject) => {
    console.log(url, `${defaults.baseURL}${url}`, method)
    axios({
      url: `${defaults.baseURL}${url}`,
      method,
      headers: defaults.headers(),
      params: method === 'get' ? variables : undefined,
      data: method !== 'get' ? variables : undefined,
      withCredentials: true,
      paramsSerializer: objectToQueryString,
    }).then(
      response => {
        console.log(response)
        resolve(response.data);
      },
      error => {
        let err = new Error()
        if (history.location.pathname === '/authenticate') {
          if (error && error.response.data) {
            err.message = error.response.data
            reject(err);
          } else {
            err.message = defaults.error.message;
            reject(err);
          }
        } else {
          if (!error || !error.response || !error.response.data) {
            err.message = defaults.error.message;
            reject(err);
          } else if (error.response.status === 401) {
            history.push('/authenticate');
          } else if (error && error.response.data) {
            err.message = error.response.data
            reject(err);
          } else {
            err.message = defaults.error.message;
            reject(err);
          }
        }
      },
    );
  });

const optimisticUpdate = async (url, { updatedFields, currentFields, setLocalData }) => {
  try {
    setLocalData(updatedFields);
    await api('put', url, updatedFields);
  } catch (error) {
    setLocalData(currentFields);
  }
};

export default {
  get: (...args) => api('get', ...args),
  post: (...args) => api('post', ...args),
  put: (...args) => api('put', ...args),
  patch: (...args) => api('patch', ...args),
  delete: (...args) => api('delete', ...args),
  optimisticUpdate,
};
